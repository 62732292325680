
class CustomerApi {

    getList({ page = 1, itemsPerPage = 10, sorter = null, filters = {}, search = '' }) {
        let url = apiUrl + 'customers';

        // Build query string parameters
        const params = new URLSearchParams();

        params.append('page', page);
        params.append('itemsPerPage', itemsPerPage);

        if (sorter?.column) {
            params.append('sortColumn', sorter.column);
            params.append('sortAsc', sorter.asc);
        }

        if (search) {
            params.append('search', search);
        }

        // Append filters to the query string
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                params.append(`filters[${key}]`, filters[key]);
            }
        });

        // Add query string to URL
        url += `?${params.toString()}`;

        console.log(url);
        
        return api.call('get', url).then(({ data }) => {
            return data; // Expected to return { items, totalItems }
        });
    }

    getListWithoutFilter() {
        var url = apiUrl + 'customers';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    get(id) {
        var url = apiUrl + 'customers/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }
 
    create(data) {
        var url = apiUrl + 'customers';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data) {
        var url = apiUrl + 'customers/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }
    delete(id) {
        var url = apiUrl + 'customers/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default CustomerApi;